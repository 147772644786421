<template>
  <v-navigation-drawer v-model="drawer" app light class="hidden-lg-and-up">
    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        :to="item.path"
      >
        <v-list-item-content>{{ item.title }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "DefaultSideMenu",
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawer: {
      get: function () {
        return this.sidebar;
      },
      set: function () {},
    },
  },
  data() {
    return {};
  },
};
</script>
